import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useSourContext } from '@wayste/sour-context';
import { Button, TextField } from '@wayste/sour-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import PasswordRequirements from '../../components/PasswordRequirements';
import routes from '../../utils/routes';
import UnauthenticatedWrapper from './unauthenticated-wrapper';

const NewPasswordRequired = () => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (location && (!location.state || !location.state.username || !location.state.password)) {
            navigate(routes.auth.login, { replace: true });
        }
    }, [location]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await client.auth().newPasswordChallenge({
                newPassword,
            });
        } catch {
            setShowToast({
                message: 'Something went wrong. Please try again.',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <UnauthenticatedWrapper variant="map">
            <div className="flex flex-col justify-center gap-4">
                <div className="px-4 pb-2">
                    <h4 className="text-sourgum-greyblue-900 pb-1 text-xl font-semibold leading-7 tracking-tight">New Password Required</h4>
                    <div className="text-sm text-gray-600">Your password needs to be updated. Create a new password below.</div>
                </div>
                <TextField
                    label="New password"
                    type="password"
                    inputProps={{
                        value: newPassword,
                        onChange: (event) => setNewPassword(event.target.value),
                        autoComplete: 'new-password',
                    }}
                />
                <PasswordRequirements password={newPassword} setValid={setIsValid} />
                <TextField
                    label="Confirm password"
                    type="password"
                    inputProps={{
                        value: confirmPassword,
                        onChange: (event) => setConfirmPassword(event.target.value),
                        autoComplete: 'no',
                    }}
                    error={
                        confirmPassword && confirmPassword !== newPassword ? { message: "Passwords don't match", type: 'value' } : undefined
                    }
                />
                <div className="pt-4">
                    <Button
                        onClick={handleSubmit}
                        disabled={!isValid || confirmPassword !== newPassword}
                        className="btn-primary w-full px-5 py-2.5 text-base font-bold leading-tight"
                        loading={isLoading}
                    >
                        Update Password
                    </Button>
                </div>
            </div>
        </UnauthenticatedWrapper>
    );
};

export default NewPasswordRequired;
