import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Button, TextField } from '@wayste/sour-ui';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PasswordRequirements from '../../components/PasswordRequirements';
import routes from '../../utils/routes';
import UnauthenticatedWrapper from './unauthenticated-wrapper';

interface FormProps {
    email: string;
    password: string;
}

const Login = () => {
    const navigate = useNavigate();
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [activeView, setActiveView] = useState('login');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('');

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { isSubmitting, errors },
    } = useForm<FormProps>({
        mode: 'all',
        defaultValues: {
            email: '',
            password: '',
        },
    });

    // get query params for tempPassword username and set them in state
    useEffect(() => {
        const url = window.location.href.split('?')[1];
        if (!url) return;
        const params = qs.parse(url, {
            // not uri encoded
            ignoreQueryPrefix: true,
        });
        const tempPass = params.tempPassword;
        const username = params.username;

        if (username && typeof username === 'string') {
            setValue('email', username);
        }

        if (tempPass && typeof tempPass === 'string') {
            setValue('password', tempPass);
        }

        if (tempPass && username) {
            handleLoginSubmit(getValues());
        }
    }, []);

    const handleLoginSubmit = async (values: { email: string; password: string }) => {
        try {
            setIsLoading(true);
            const response = await client.auth().login({ email: values.email, password: values.password });
            if (response?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setEmail(values.email);
                setActiveView('NEW_PASSWORD_REQUIRED');
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
        }
        setIsLoading(false);
    };

    const handleForcePasswordResetSubmit = async () => {
        try {
            setErrorMessage('');
            setIsLoading(true);
            await client.auth().newPasswordChallenge({ newPassword });
            await handleLoginSubmit({ email, password: newPassword });
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
            setErrorMessage('Something went wrong. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const loginView = (
        <div className="flex flex-1 flex-col">
            <form className="flex flex-1 flex-col justify-center gap-4" onSubmit={handleSubmit(handleLoginSubmit)}>
                <h4 className="text-sourgum-greyblue-900 px-4 pb-2 text-xl font-semibold leading-7 tracking-tight">Welcome to Wayste</h4>
                <TextField
                    inputProps={{ ...register('email', { required: 'Your email is required' }) }}
                    label="Email or username"
                    type="email"
                    error={errors.email}
                    required
                />
                <TextField
                    inputProps={{ ...register('password', { required: 'Your password is required' }) }}
                    label="Password"
                    type="password"
                    error={errors.email}
                    required
                />
                <div className="pt-4">
                    <Button
                        id="login-btn"
                        type="submit"
                        disabled={isSubmitting}
                        className="btn-primary w-full px-5 py-2.5 text-base font-bold leading-tight"
                        loading={isLoading}
                    >
                        Log In
                    </Button>
                    <div className="flex justify-end pt-2">
                        <Button className="btn-primary-text-only" onClick={() => navigate(routes.auth.forgotPassword)}>
                            Forgot Password?
                        </Button>
                    </div>
                </div>
            </form>
            <div className="text-center text-sm">
                New to Wayste?{' '}
                <a className="text-primary-400 cursor-pointer" href={routes.scheduleWaysteDemo} target="_blank">
                    Sign up
                </a>
            </div>
        </div>
    );

    const forcePasswordResetView = (
        <div className="flex flex-col justify-center gap-4">
            <div className="px-4 pb-2">
                <h4 className="text-sourgum-greyblue-900 pb-1 text-xl font-semibold leading-7 tracking-tight">New Password Required</h4>
                <div className="text-sm text-gray-600">Your password needs to be updated. Create a new password below.</div>
            </div>
            <TextField
                label="New password"
                inputProps={{
                    id: 'force-password-reset-new-password',
                    autoComplete: 'new-password',
                    value: newPassword,
                    onChange: (event) => setNewPassword(event.target.value),
                }}
                type="password"
            />
            <PasswordRequirements password={newPassword} setValid={setIsValid} />
            <TextField
                label="Confirm password"
                type="password"
                inputProps={{
                    value: confirmPassword,
                    onChange: (event) => setConfirmPassword(event.target.value),
                    autoComplete: 'no',
                }}
                error={confirmPassword && confirmPassword !== newPassword ? { message: "Passwords don't match", type: 'value' } : undefined}
            />
            <div className="pt-4">
                <Button
                    loading={isLoading}
                    onClick={handleForcePasswordResetSubmit}
                    disabled={!isValid || confirmPassword !== newPassword}
                    className="btn-primary w-full px-5 py-2.5 text-base font-bold leading-tight"
                >
                    Update Password
                </Button>
            </div>
        </div>
    );

    const getView = () => {
        switch (activeView) {
            case 'login':
                return loginView;
            case 'NEW_PASSWORD_REQUIRED':
                return forcePasswordResetView;
            default:
                return loginView;
        }
    };

    return (
        <UnauthenticatedWrapper variant="map" errorMessage={errorMessage}>
            {getView()}
        </UnauthenticatedWrapper>
    );
};

export default Login;
