import React from 'react';
import { SourgumLeafGreenCircle, WaysteFullLogo } from '@wayste/sour-icons';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import mapImage from '../../assets/images/auth-map-background.png';

const Alert = ({ children }: { children: string | React.ReactNode }) => {
    return (
        <div className="flex items-center gap-2 bg-red-100 px-4 py-3.5 text-sm text-red-900">
            <InformationCircleIcon className="size-6" />
            {children}
        </div>
    );
};

const UnauthenticatedWrapper = ({
    children,
    variant = 'standard',
    errorMessage,
}: {
    children: React.ReactNode;
    variant?: 'standard' | 'map' | 'sourgum-branded';
    errorMessage?: string;
}) => {
    if (variant === 'map') {
        return (
            <div className="relative flex min-h-screen flex-1 grow flex-col">
                {/* Radial Gradient Background */}
                <div
                    className="absolute left-0 top-0 z-10 size-full"
                    style={{ background: 'radial-gradient(circle, rgba(0,0,0,0.1) 10%, rgba(0,0,0,0.2) 100%)' }}
                />
                {/* Image Overlay */}
                <div
                    className="image-overlay absolute left-0 top-0 size-full bg-cover bg-center opacity-30"
                    style={{ backgroundImage: `url(${mapImage})` }}
                />
                <div className="z-20 flex flex-1 flex-col">
                    <div className="flex flex-1 flex-col">
                        {errorMessage ? <Alert>{errorMessage}</Alert> : null}
                        <div className="flex flex-1 items-center justify-center">
                            <div className="mx-5 flex min-h-[456px] w-full max-w-2xl rounded-lg shadow">
                                <div className="bg-main-sidebar flex-1 rounded-lg sm:rounded-r-none">
                                    <div className="flex h-full flex-col justify-center p-4">{children}</div>
                                </div>
                                <div
                                    className="hidden flex-1 items-end justify-end rounded-r-lg bg-cover bg-center bg-no-repeat sm:flex"
                                    style={{ backgroundImage: `url(${mapImage})` }}
                                >
                                    <div className="p-4">
                                        <WaysteFullLogo className="fill-sourgum-greyblue-900 h-5 w-fit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (variant === 'sourgum-branded') {
        return (
            <div className="bg-main-sidebar flex min-h-screen flex-1 grow flex-col">
                <div className="bg-sourgum-greyblue-900 flex items-center justify-center p-3 text-white">
                    <div className="flex h-full items-center gap-2">
                        <SourgumLeafGreenCircle className="xs:inline-block h-8 w-auto" />
                        <span className="whitespace-nowrap text-2xl font-bold leading-tight tracking-tight text-white">
                            <span className="xs:inline-block hidden">Sourgum</span> Partner Portal
                        </span>
                        <div className="bg-sourgum-green-500 my-0.5 h-5 w-[0.3px]" />
                        <span className="h-[22px] whitespace-nowrap text-sm font-normal italic leading-[1.42857] tracking-[0.21px] text-white">
                            <span className="xs:inline-block hidden">powered</span> by
                        </span>
                        <WaysteFullLogo className="h-4 w-auto fill-white" />
                    </div>
                </div>
                <div className="flex flex-1 flex-col">{children}</div>
            </div>
        );
    }

    return (
        <div className="bg-main-sidebar flex min-h-screen flex-1 grow flex-col">
            <div className="bg-sourgum-greyblue-900 flex items-center justify-center p-3 text-white">
                <WaysteFullLogo style={{ fontSize: 200, height: 50 }} />
            </div>
            <div className="flex flex-1 flex-col">{children}</div>
        </div>
    );
};

export default UnauthenticatedWrapper;
