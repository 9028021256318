import { useState } from 'react';
import { useCurrentAccount, useIsAppAdmin } from '@alliance-disposal/client';
import type { Profile } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { WaysteFullLogo } from '@wayste/sour-icons';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import iOSBadge from '../assets/ios-appstore-badge.svg';
import NewAccountDialog from '../components/new-account-dialog';
import routes from '../utils/routes';
import Routes from './Routes';
import NavBar from './navigation/navbar';
import Toolbar from './navigation/toolbar';

const SHOW_BANNER = false;

const Layout = ({ onSignOut, userProfile }: { onSignOut: () => void; userProfile: Profile.ProfileTransport }) => {
    const { isDesktop } = useSourContext();
    const { hauler: userHaulerInfo, featureFlags } = useCurrentAccount();
    const roles = userProfile.authorities.flatMap((authority) => authority.roles);
    const navigate = useNavigate();
    const { isAdmin } = useIsAppAdmin();
    const [adminOverrideClose, setIsAdminClose] = useState(false);
    const [toolBarOpen, setToolBarOpen] = useState(isDesktop);
    const [showWaysteAdBanner, setShowWaysteAdBanner] = useState(true);

    // Start of hotkeys //////////////////////////////////////////
    useHotkeys('mod+k', () => navigate(routes.search.list));
    useHotkeys('mod+\\', () => setToolBarOpen(!toolBarOpen));
    // End of hotkeys //////////////////////////////////////////

    const handleSignOut = () => {
        onSignOut();
    };

    if (!roles.includes('HAULER_ADMIN')) {
        return (
            <div className="flex max-h-screen min-h-screen flex-grow flex-col justify-center overflow-auto px-2">
                <NavBar
                    noInteract
                    onLogout={onSignOut}
                    toolBarOpen={false}
                    setToolBarOpen={() => {}}
                    isDesktop={isDesktop}
                    className="fixed left-0 top-0 z-50 w-full"
                />

                <div className="flex w-fit flex-col items-center justify-center gap-2 self-center justify-self-center rounded border border-black/10 bg-white p-4 shadow-md">
                    <WaysteFullLogo className="fill-wayste-blue-900 h-14 w-auto" />

                    {roles.includes('DRIVER') ? (
                        <h3 className="px-2 text-center text-xl font-bold leading-none tracking-wide text-[#48484C]">
                            Looks like you are a driver!
                            <br />
                            <span className="text-sm">Please download the Wayste Driver app to access your account.</span>
                        </h3>
                    ) : (
                        <h3 className="text-xl">You do not have access to this portal.</h3>
                    )}
                    {roles.includes('DRIVER') && (
                        <div className="mt-4 flex w-full flex-col items-center justify-center gap-4 md:gap-0">
                            <a href="https://apple.co/44He7A8" target="_blank" rel="noopener noreferrer">
                                <img className="h-12 object-contain py-2 md:h-16" src={iOSBadge} alt="Download on the App Store" />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.wayste.waystedriverapp&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="h-12 object-contain md:h-16"
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                />
                            </a>
                        </div>
                    )}
                    <button onClick={handleSignOut} className="btn-primary mt-8">
                        Logout
                    </button>
                </div>
            </div>
        );
    }

    if (userHaulerInfo === null) return;

    return (
        <div className="flex max-h-screen min-h-screen flex-grow flex-col overflow-auto bg-[#FCFCFD]">
            <NavBar
                onLogout={onSignOut}
                toolBarOpen={toolBarOpen}
                setToolBarOpen={() => {
                    setToolBarOpen(!toolBarOpen);
                }}
                isDesktop={isDesktop}
                navigateToSearch={() => {
                    navigate(routes.search.list);
                }}
            />

            <div className="relative flex flex-1 flex-grow flex-row overflow-auto">
                <Toolbar
                    desktopToolBarOpen={toolBarOpen}
                    isDesktop={isDesktop}
                    toggleOpen={() => setToolBarOpen(!toolBarOpen)}
                    featureFlags={featureFlags}
                />

                <main
                    style={{
                        minHeight: '94vh',
                    }}
                    className="flex flex-grow flex-col overflow-auto"
                >
                    {/* Service Banner */}
                    {SHOW_BANNER && Date.now() < new Date('2023-11-02T22:00:00.000Z').getTime() && (
                        <div className="bg-blue-600 p-2 text-center text-xs text-white">
                            <span className="font-bold">Service Advisory:</span> Between 2:00 PM and 5:00 PM on Thursday, November 2, 2023
                            we will be performing maintenance on our servers. During this time, you may experience intermittent
                            inconsistencies with invoice numbers. All functionality will remain available during this time. We apologize for
                            any inconvenience this may cause.
                        </div>
                    )}
                    {userHaulerInfo.waysteLite && showWaysteAdBanner && (
                        <div className="hidden w-full bg-[#FBDEBB] bg-opacity-70 px-4 py-2 text-center text-sm md:flex">
                            <div className="flex-1">
                                Accelerate your hauling efficiency with cutting-edge software, setup is fast and easy.{' '}
                                <a href={routes.scheduleWaysteDemo} target="_blank" className="cursor-pointer text-[#0074b2]">
                                    Schedule a Wayste Demo
                                </a>
                                .
                            </div>
                            <div className="btn-icon-dense" onClick={() => setShowWaysteAdBanner(false)}>
                                <XMarkIcon className="size-4" />
                            </div>
                        </div>
                    )}
                    <NewAccountDialog
                        open={Boolean(userProfile) && !adminOverrideClose && userHaulerInfo.materials.length === 0}
                        onClose={() => setIsAdminClose(true)}
                    />
                    <Routes waysteLite={userHaulerInfo.waysteLite} isAppAdmin={isAdmin} />
                </main>
            </div>
        </div>
    );
};

export default Layout;
