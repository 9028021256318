import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import HaulerToAdminUpdates from '../../pages/public/HaulerToAdminUpdates';
import VerifyAccount from '../../pages/sign-up/verify-account';
import routes from '../../utils/routes';
import ForgotPassword from './forgot-password-page';
import Login from './login-page';
import NewPasswordRequired from './new-password-required-page';

const PublicRFPUpdate = React.lazy(() => import('../../pages/public/public-rfp-update'));
const SignUp = React.lazy(() => import('../../pages/sign-up/sign-up-page'));

const UnauthenticatedRoutes = () => (
    <ReactRoutes>
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.newPasswordRequired} element={<NewPasswordRequired />} />
        <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
        {/* Public Routes */}
        <Route path={routes.public.updateAdmin} element={<HaulerToAdminUpdates />} />
        <Route path={routes.public.signUp} element={<SignUp />} />
        <Route path={routes.public.verifyAccount} element={<VerifyAccount />} />
        <Route path="/*" element={<Login />} />
        <Route path={routes.public.requestForProposal(':accessLink')} element={<PublicRFPUpdate />} />
    </ReactRoutes>
);

export default UnauthenticatedRoutes;
