import { Dumpster, Hauler } from '@alliance-disposal/transport-types';
import { Button, RHFRules, Select, SelectOption, TextField, phoneNumberMasker } from '@wayste/sour-ui';
import { formatE164ToUSPhoneNumber, formatUSPhoneNumberToE164 } from '@wayste/utils';
import { Controller, useForm } from 'react-hook-form';
import { allPossibleDumpsterSizes } from '../../utils';

interface FormProps {
    name: string;
    contactEmail: string;
    contactPhoneNumber: string;
    website?: string;
    stateLicenseNumber?: string;
    dumpsterSizes?: {
        size: number;
        type: Dumpster.DumpsterType;
    }[];
}

interface Props {
    haulerAccount?: Hauler.HaulerTransport;
    onSubmit: (data: FormProps) => void;
    hideSubmit?: boolean;
}

const AccountForm = ({ haulerAccount, onSubmit, hideSubmit }: Props) => {
    const onHandleSubmit = async (data: FormProps) => {
        onSubmit({
            ...data,
            contactPhoneNumber: formatUSPhoneNumberToE164(data.contactPhoneNumber),
        });
    };

    const {
        control,
        formState: { errors, isSubmitting },
        setValue,
        handleSubmit,
        watch,
    } = useForm<FormProps>({
        mode: 'all',
        // prepareFormValues(item)
        defaultValues: {
            name: haulerAccount?.name || '',
            contactEmail: haulerAccount?.contactEmail || '',
            contactPhoneNumber: haulerAccount?.contactPhoneNumber || '',
            website: haulerAccount?.website || '',
            stateLicenseNumber: haulerAccount?.stateLicenseNumber || '',
            // @ts-expect-error - the type is wrong
            dumpsterSizes: haulerAccount?.dumpsterSizes || [],
        },
    });

    console.log(watch());
    return (
        <form id="account-settings-form" onSubmit={handleSubmit(onHandleSubmit)} autoComplete="off">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <Controller
                    control={control}
                    name="name"
                    rules={{
                        required: {
                            value: true,
                            message: 'Company name is required',
                        },
                    }}
                    render={({ field }) => (
                        <TextField
                            label="Company Name"
                            error={errors.name}
                            required
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="website"
                    render={({ field }) => (
                        <TextField
                            label="Website"
                            error={errors.website}
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="contactEmail"
                    rules={{
                        required: {
                            value: true,
                            message: 'Fall back primary email is required',
                        },
                        ...RHFRules.email,
                    }}
                    render={({ field }) => (
                        <TextField
                            label="Main Email"
                            error={errors.contactEmail}
                            required
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="contactPhoneNumber"
                    rules={{
                        required: {
                            value: true,
                            message: 'Fall back primary phone number is required',
                        },
                        ...RHFRules.phoneNumber,
                    }}
                    render={({ field }) => (
                        <TextField
                            startAdornment="+1"
                            label="Main Phone Number"
                            error={errors.contactPhoneNumber}
                            required
                            inputProps={{
                                ...field,
                                value: formatE164ToUSPhoneNumber(field.value),
                                onChange: (e) => {
                                    setValue(`contactPhoneNumber`, formatUSPhoneNumberToE164(phoneNumberMasker(e.target.value)));
                                },
                            }}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="stateLicenseNumber"
                    render={({ field }) => (
                        <TextField
                            label="State License Number"
                            inputProps={{
                                ...field,
                            }}
                        />
                    )}
                />
                {haulerAccount?.waysteLite && (
                    <>
                        <hr className="col-span-2 border-t border-gray-300" />

                        <div>
                            <Controller
                                control={control}
                                name="dumpsterSizes"
                                render={({ field }) => (
                                    <Select
                                        label="Sizes Carried"
                                        multiple
                                        value={field.value?.map((item) => String(item.size))}
                                        onSelect={(value) => field.onChange(value.map((item) => ({ size: item, type: 'OPEN_TOP' })))}
                                        inputProps={{
                                            id: 'openTopDumpsterSizes',
                                        }}
                                    >
                                        {allPossibleDumpsterSizes.map((item) => (
                                            <SelectOption key={`openTopDumpsterSizes-${item}`} value={item}>
                                                {`${item} YD`}
                                            </SelectOption>
                                        ))}
                                    </Select>
                                )}
                            />
                        </div>
                    </>
                )}
            </div>
            {!hideSubmit && (
                <div className="flex justify-end">
                    <Button className="btn-primary mt-2" type="submit" disabled={isSubmitting}>
                        {haulerAccount ? 'Update' : 'Save'}
                    </Button>
                </div>
            )}
        </form>
    );
};

export default AccountForm;
