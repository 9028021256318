import React, { Component, Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import PageLoader from '../components/PageLoader/PageLoader';
// import Four0Four from './Four0Four';
import routes from '../utils/routes';

const OrderList = React.lazy(() => import('../pages/OrderList'));
const OrderCreate = React.lazy(() => import('../pages/order-create'));
const OrderUpdate = React.lazy(() => import('../components/OrderUpdate'));
const OrderDetails = React.lazy(() => import('../components/OrderDetails'));
const Settings = React.lazy(() => import('../pages/account-settings'));
const AccountingIntegrations = React.lazy(() => import('../pages/account-settings/pages/accounting-integrations'));
const QuickBooksOnlineMapping = React.lazy(() => import('../pages/account-settings/pages/quick-books-online-mapping'));
const QuickBooksOnlineAuthCallback = React.lazy(() => import('../pages/account-settings/pages/QuickBooksOnlineAuthCallback'));
const AccountUpdate = React.lazy(() => import('../pages/account-settings/pages/account-update'));
const QuickBooksDesktopSettings = React.lazy(() => import('../pages/account-settings/pages/quickbooks-desktop-settings'));
const QuickBooksDesktopDownload = React.lazy(() => import('../pages/account-settings/pages/quickbooks-desktop-download'));
const ContainerMap = React.lazy(() => import('../pages/equipment/container-map'));
const CustomerDetails = React.lazy(() => import('../components/CustomerDetails'));
const CustomerList = React.lazy(() => import('../pages/customer-list/customer-list'));
const GlobalSearch = React.lazy(() => import('../pages/GlobalSearch'));
const UserUpdate = React.lazy(() => import('../pages/account-settings/pages/user-update'));
const HaulerToAdminUpdates = React.lazy(() => import('../pages/public/HaulerToAdminUpdates'));
const WaysteLiteDisabled = React.lazy(() => import('../pages/WaysteLiteDisabled'));
const HaulerPricing = React.lazy(() => import('../pages/HaulerPricing/HaulerPricing'));
const HaulerPricingDetails = React.lazy(() => import('../pages/HaulerPricingDetails/HaulerPricingDetails'));
const BillingPage = React.lazy(() => import('../pages/billing/billing-page'));
const ROScheduling = React.lazy(() => import('../pages/ro-scheduling/ro-schedule'));
const AppAdmin = React.lazy(() => import('../pages/app-admin/app-admin'));
const XXXLab = React.lazy(() => import('../components/XXXLab'));
const DriverTracking = React.lazy(() => import('../pages/driver-tracking/driver-tracking'));
const TimeSheet = React.lazy(() => import('../pages/time-sheet/time-sheet'));
const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'));
const RFPList = React.lazy(() => import('../pages/sourgum-request-for-proposal/rfp-list'));
const RFPDetails = React.lazy(() => import('../pages/sourgum-request-for-proposal/rfp-details'));
const RFPUpdate = React.lazy(() => import('../pages/sourgum-request-for-proposal/rfp-update'));
const PublicRFPUpdate = React.lazy(() => import('../pages/public/public-rfp-update'));

const InventoryPage = React.lazy(() => import('../pages/equipment/inventory-page'));
const VehiclePage = React.lazy(() => import('../pages/equipment/vehicle-page'));
const ContainerPage = React.lazy(() => import('../pages/equipment/container-page'));

const SubscriptionCreate = React.lazy(() => import('../pages/commercial-crm/subscription-forms/subscription-create'));
const SubscriptionDetails = React.lazy(() => import('../pages/commercial-crm/subscription-details'));
const SubscriptionEquipmentUpdate = React.lazy(() => import('../pages/commercial-crm/subscription-forms/subscription-equipment-update'));
const SubscriptionList = React.lazy(() => import('../pages/commercial-crm/subscription-list'));

const DispatcherDashboard = React.lazy(() => import('../pages/commercial-dispatching/dispatcher-dashboard'));
const RouteDetails = React.lazy(() => import('../pages/commercial-dispatching/route-details'));
const RouteList = React.lazy(() => import('../pages/commercial-dispatching/route-list'));
const RouteTempChange = React.lazy(() => import('../pages/commercial-dispatching/route-temp-change-wrapper'));
const RoutingView = React.lazy(() => import('../pages/commercial-dispatching/routing-view-wrapper'));
const RouteSchedule = React.lazy(() => import('../pages/commercial-dispatching/route-schedule'));
const EventList = React.lazy(() => import('../pages/commercial-dispatching/event-list'));
const DeliveryPickupSchedule = React.lazy(() => import('../pages/commercial-dispatching/delivery-pickup-schedule/schedule'));
// Reports
const ReportList = React.lazy(() => import('../pages/reports/report-list'));
const SalesTaxLiabilityReport = React.lazy(() => import('../pages/reports/sales-tax-liability-report'));

const DevTest = React.lazy(() => import('../pages/dev-test'));
const CommercialPreview = React.lazy(() => import('../pages/commercial-preview/'));
const PartnerPortalConnect = React.lazy(() => import('../pages/partner-portal-connect/partner-portal-connect'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(ReactRoutes);
class Routes extends Component {
    constructor(props) {
        super(props);
        this.previousLocation = this.props.location;
    }

    componentDidUpdate() {
        const { location } = this.props;
        if (!(location?.state && location?.state?.modal)) {
            return (this.previousLocation = this.props.location);
        }
        return null;
    }

    render() {
        const { waysteLite, isAppAdmin } = this.props;
        // const isModal = location.state && location.state.modal && this.previousLocation !== location;

        return (
            <Suspense fallback={<PageLoader />}>
                <SentryRoutes>
                    {/* location={isModal ? this.previousLocation : location} */}
                    {/* Orders */}
                    <Route path={routes.dashboard} element={<Dashboard />} />
                    <Route path={routes.orders.list} element={<OrderList />} />
                    <Route path={routes.orders.create} element={waysteLite ? <WaysteLiteDisabled /> : <OrderCreate />} />
                    <Route path={routes.orders.update(':id')} element={waysteLite ? <WaysteLiteDisabled /> : <OrderUpdate />} />
                    <Route path={routes.orders.details(':id')} element={<OrderDetails />} />
                    {/* Schedules */}
                    <Route path={routes.roScheduling.list} element={waysteLite ? <WaysteLiteDisabled /> : <ROScheduling />} />
                    {/* Customers */}
                    <Route path={routes.customers.details(':id')} element={<CustomerDetails />} />
                    <Route path={routes.customers.list} element={<CustomerList />} />
                    {/* Billing */}
                    <Route path={routes.billing.list} element={<BillingPage />} />
                    {/* Pricing */}
                    <Route path={routes.pricing.list} element={<HaulerPricing />} />
                    <Route path={routes.pricing.details(':id')} element={<HaulerPricingDetails />} />

                    <Route path={routes.equipment.map} element={waysteLite ? <WaysteLiteDisabled /> : <ContainerMap />} />
                    {/* SGW RFP */}
                    <Route path={routes.requestForProposal.list} element={<RFPList />} />
                    <Route path={routes.requestForProposal.details(':id')} element={<RFPDetails />} />
                    <Route path={routes.requestForProposal.update(':id')} element={<RFPUpdate />} />
                    {/* Partner Portal Connect */}
                    <Route path={routes.partnerPortalConnect.list} element={<PartnerPortalConnect />} />
                    {/* Settings */}
                    <Route path={routes.settings.list} element={<Settings />} />
                    <Route path={routes.settings.updateAccount} element={<AccountUpdate />} />
                    <Route path={routes.settings.accountingIntegrations} element={<AccountingIntegrations />} />
                    <Route path={routes.settings.quickBooksOnlineMapping} element={<QuickBooksOnlineMapping />} />
                    <Route path={routes.settings.quickBooksOnlineAuthCallback} element={<QuickBooksOnlineAuthCallback />} />
                    <Route path={routes.settings.quickBooksDesktopSettings} element={<QuickBooksDesktopSettings />} />
                    <Route path={routes.settings.quickBooksDesktopDownload} element={<QuickBooksDesktopDownload />} />
                    {/* Users */}
                    <Route path={routes.users.update(':id')} element={<UserUpdate />} />
                    {/* Billing */}
                    {/* Search */}
                    <Route path={routes.search.list} element={<GlobalSearch />} />

                    <Route path={routes.driverTracking.list} element={waysteLite ? <WaysteLiteDisabled /> : <DriverTracking />} />
                    <Route path={routes.timeSheet.list} element={waysteLite ? <WaysteLiteDisabled /> : <TimeSheet />} />

                    {/* App Admin */}
                    {isAppAdmin && <Route path={routes.appAdmin.list} element={<AppAdmin />} />}
                    {isAppAdmin && <Route path={routes.lab.list} element={<XXXLab />} />}

                    <Route path={routes.equipment.vehicles} element={<VehiclePage />} />
                    <Route path={routes.equipment.containers} element={<ContainerPage />} />
                    <Route path={routes.equipment.inventory} element={<InventoryPage />} />
                    <Route path={routes.subscriptions.list} element={<SubscriptionList />} />
                    <Route path={routes.subscriptions.create} element={<SubscriptionCreate />} />
                    <Route path={routes.subscriptions.editEquipment(':id')} element={<SubscriptionEquipmentUpdate />} />
                    <Route path={routes.subscriptions.details(':id')} element={<SubscriptionDetails />} />
                    <Route path={routes.dispatching.dashboard} element={<DispatcherDashboard />} />
                    <Route path={routes.dispatching.commercialRoutes.list} element={<RouteList />} />
                    <Route path={routes.dispatching.commercialRoutes.details(':id')} element={<RouteDetails />} />
                    <Route path={routes.dispatching.routing} element={<RoutingView />} />
                    <Route path={routes.dispatching.routeTempChange} element={<RouteTempChange />} />
                    <Route path={routes.dispatching.schedule} element={<RouteSchedule />} />
                    <Route path={routes.dispatching.events} element={<EventList />} />
                    <Route path={routes.dispatching.containerDelivery} element={<DeliveryPickupSchedule />} />
                    <Route path={routes.reports.list} element={<ReportList />} />
                    <Route path={routes.reports.taxLiability} element={<SalesTaxLiabilityReport />} />

                    {import.meta.env.MODE !== 'production' && (
                        <>
                            <Route path={routes.devTest} element={<DevTest />} />
                        </>
                    )}
                    <Route path={routes.reports.list} element={<ReportList />} />
                    <Route path={routes.reports.taxLiability} element={<SalesTaxLiabilityReport />} />

                    <Route path={routes.commercialPreview} element={<CommercialPreview />} />
                    {/* Public Routes */}
                    <Route path={routes.public.updateAdmin} element={<HaulerToAdminUpdates />} />
                    <Route path={routes.public.requestForProposal(':accessLink')} element={<PublicRFPUpdate />} />

                    {/* 404 */}
                    {/* <Route element={<Four0Four} /> /> */}
                    <Route path="/*" element={<OrderList />} />
                </SentryRoutes>
            </Suspense>
        );
    }
}

export default Routes;
