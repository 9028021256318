import { useEffect, useState } from 'react';
import { useSourContext } from '@wayste/sour-context';
import { Loading } from '@wayste/sour-ui';
import axios, { isAxiosError } from 'axios';
import { NavLink } from 'react-router-dom';
import UnauthenticatedWrapper from '../../structure/Auth/unauthenticated-wrapper';
import routes from '../../utils/routes';

export default function VerifyAccount() {
    const { setShowAlert } = useSourContext();
    const [verifyComplete, setVerifyComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');

    const verifyToken = async (token: string) => {
        setLoading(true);
        try {
            await axios.get(`${import.meta.env.VITE_BASE_API_URL}/profile/verify/${token}`);
            setVerifyComplete(true);
        } catch (error) {
            console.error(error);
            let message = 'There was an error verifying your account. Please try again.';
            if (isAxiosError(error)) {
                message = error.response?.data?.additionalInfo || message;
            }
            setShowAlert({
                title: 'Error',
                message,
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token === '' || loading) return;
        verifyToken(token);
    }, [token]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            setToken(token);
        }
    }, []);

    return (
        <UnauthenticatedWrapper variant="map">
            {verifyComplete ? (
                <div className="flex flex-col gap-4">
                    <h2 className="text-sourgum-greyblue-900 pb-2 text-center text-xl font-semibold leading-7 tracking-tight">
                        Verification Complete
                    </h2>
                    <div>
                        <p className="pb-2 text-center">Your account has been verified.</p>
                        <p className="text-center text-xs">
                            Please <NavLink to={routes.auth.login}>login</NavLink> to continue.
                        </p>
                    </div>
                    {/* Button to sign in */}
                    <div className="pt-4">
                        <NavLink className="btn-primary w-full px-5 py-2.5 text-base font-bold leading-tight" to={routes.auth.login}>
                            Go to Log In
                        </NavLink>
                    </div>
                </div>
            ) : loading ? (
                <div className="flex flex-col items-center justify-center gap-2">
                    <h2>Verifying your account. Please wait...</h2>
                    <Loading />
                </div>
            ) : (
                <>
                    <h2 className="text-sourgum-greyblue-900 pb-4 text-center text-xl font-semibold leading-7 tracking-tight">
                        Somethings gone wrong!
                    </h2>
                    <p className="mb-2 text-center">Please sign up with your unique link or contact support.</p>
                </>
            )}
        </UnauthenticatedWrapper>
    );
}
