import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useSourContext } from '@wayste/sour-context';
import { Button, TextField } from '@wayste/sour-ui';
import { useNavigate } from 'react-router-dom';
import PasswordRequirements from '../../components/PasswordRequirements';
import routes from '../../utils/routes';
import UnauthenticatedWrapper from './unauthenticated-wrapper';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { setShowToast } = useSourContext();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [resetResponse, setResetResponse] = useState<string>();
    const [showEnterCode, setShowEnterCode] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const client = useWaysteClient();

    const handleUsernameSubmit = async () => {
        try {
            setIsLoading(true);
            const response = await client.auth().forgotPassword({ email: username });

            setResetResponse(response.nextStep.codeDeliveryDetails.destination);
            setShowEnterCode(true);
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerificationSubmit = async () => {
        setIsLoading(true);
        try {
            await client.auth().verifyForgotPasswordCode({
                userName: username,
                code: verificationCode,
                password: newPassword,
            });
            setShowToast({ severity: 'success', message: 'Your password has been updated!' });
            await client.auth().login({ email: username, password: newPassword });
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            }

            return;
        } finally {
            setIsLoading(false);
        }
    };

    const forgotPasswordView = (
        <div className="flex flex-col justify-center gap-4">
            <h4 className="text-sourgum-greyblue-900 px-4 pb-2 text-xl font-semibold leading-7 tracking-tight">Forgot Password</h4>
            <TextField
                label="Username"
                type="username"
                inputProps={{
                    value: username,
                    onChange: (event) => setUsername(event.target.value),
                }}
            />
            <div className="pt-4">
                <Button
                    className="btn-primary w-full px-5 py-2.5 text-base font-bold leading-tight"
                    type="submit"
                    onClick={handleUsernameSubmit}
                    loading={isLoading}
                >
                    Send Recovery Code
                </Button>
                <div className="flex justify-end pt-2">
                    <Button className="btn-primary-text-only" onClick={() => navigate(routes.auth.login)}>
                        Sign In
                    </Button>
                </div>
            </div>
        </div>
    );

    const enterCodeView = (
        <div className="flex flex-col justify-center gap-4">
            <h4 className="text-sourgum-greyblue-900 px-4 pb-2 text-xl font-semibold leading-7 tracking-tight">
                Enter Verification Code Sent{resetResponse ? `to ${resetResponse}` : ''}
            </h4>
            <TextField
                label="Verification Code"
                inputProps={{
                    value: verificationCode,
                    onChange: (event) => setVerificationCode(event.target.value),
                }}
            />
            <TextField
                label="New password"
                type="password"
                inputProps={{
                    value: newPassword,
                    onChange: (event) => setNewPassword(event.target.value),
                    autoComplete: 'new-password',
                }}
            />
            <PasswordRequirements password={newPassword} setValid={setIsValid} />
            <TextField
                label="Confirm password"
                type="password"
                inputProps={{
                    value: confirmPassword,
                    onChange: (event) => setConfirmPassword(event.target.value),
                    autoComplete: 'no',
                }}
                error={confirmPassword && confirmPassword !== newPassword ? { message: "Passwords don't match", type: 'value' } : undefined}
            />
            <div className="pt-4">
                <Button
                    className="btn-primary w-full px-5 py-2.5 text-base font-bold leading-tight"
                    type="submit"
                    onClick={handleVerificationSubmit}
                    disabled={!isValid || confirmPassword !== newPassword}
                    loading={isLoading}
                >
                    Update Password
                </Button>
            </div>
        </div>
    );

    return (
        <UnauthenticatedWrapper variant="map" errorMessage={errorMessage}>
            {showEnterCode ? enterCodeView : forgotPasswordView}
        </UnauthenticatedWrapper>
    );
};

export default ForgotPassword;
