import { useEffect, useState } from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import UnauthenticatedWrapper from '../../../structure/Auth/unauthenticated-wrapper';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const HaulerToAdminUpdates = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const query = useQuery();

    const sendUpdate = async (category: string, id: string, switchID?: string) => {
        setIsLoading(true);

        const url = import.meta.env.VITE_BASE_API_URL + `/order/admin-portal/${id}/hauler-email`;

        try {
            await axios.put(url, { category, switchID });
            setSuccess(true);
        } catch (error) {
            Sentry.captureException(error);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const parsed = {
            category: query.get('category'),
            id: query.get('id'),
            switch: query.get('switch'),
        };

        if ((parsed.category === 'delivery_confirmed' || parsed.category === 'pickup_confirmed') && parsed.id) {
            sendUpdate(parsed.category, parsed.id, parsed.switch || undefined);
        } else {
            setError(true);
        }
    }, []);

    if (isLoading)
        return (
            <UnauthenticatedWrapper>
                <Loading />
            </UnauthenticatedWrapper>
        );

    if (error)
        return (
            <UnauthenticatedWrapper>
                <div className="flex flex-1 flex-col items-center justify-center bg-white p-5">
                    <ExclamationCircleIcon className="size-20 text-red-500" />
                    <h5 className="mt-2 text-2xl">An Error Occurred</h5>
                    <h6 className="text-xl">Please respond to us via email or try again in a few minutes</h6>
                </div>
            </UnauthenticatedWrapper>
        );

    if (success)
        return (
            <UnauthenticatedWrapper>
                <div className="flex flex-1 flex-col items-center justify-center bg-white p-5">
                    <CheckCircleIcon className="size-20 text-green-500" />
                    <h5 className="mt-2 text-2xl">Successfully Confirmed!</h5>
                    {/* Prompt Haulers to sign up for wayste*/}
                    <br />
                    {/*         <h6 className="text-xl">Looking for more jobs and to get paid quicker?</h6>
        <a className="bg-sourgum-greyblue-900 text-white px-6 py-3 rounded-md mt-2" href="https://wayste.com/signup">
          SIGN UP NOW
        </a> */}
                </div>
            </UnauthenticatedWrapper>
        );

    return (
        <UnauthenticatedWrapper>
            <div className="flex flex-1 flex-col items-center justify-center bg-white p-5">
                <h5 className="text-2xl">Invalid Request</h5>
                <h6 className="text-xl">Please contact us via email</h6>
            </div>
        </UnauthenticatedWrapper>
    );
};

export default HaulerToAdminUpdates;
