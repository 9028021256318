import { useEffect, useState } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

const requirementLabels = [
    { key: 'char8', label: 'Min 8 characters' },
    { key: 'number', label: '1 number' },
    { key: 'upper', label: '1 upper case letter' },
    { key: 'special', label: '1 symbol (!@#$%^)' },
    { key: 'lower', label: '1 lower case letter' },
];

type Props = {
    password: string;
    setValid: (val: boolean) => void;
};

const PasswordRequirements = ({ password, setValid }: Props) => {
    const [requirements, setRequirements] = useState<{ [key: string]: boolean }>({
        char8: false,
        upper: false,
        lower: false,
        number: false,
        special: false,
    });

    useEffect(() => {
        const newRequirements = {
            char8: password.length >= 8 ? true : false,
            upper: /.*[A-Z].*/.test(password),
            lower: /.*[a-z].*/.test(password),
            number: /.*\d.*/.test(password),
            special: /.*[-_!@#$%^&*.,"'`:;|~><?].*/.test(password),
        };

        setRequirements(newRequirements);

        if (Object.values(newRequirements).every((item) => item === true)) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [password]);

    return (
        <div>
            <div className="text-sm font-semibold">Password Requirements</div>
            <div className="mt-3.5 flex flex-row flex-wrap text-sm">
                {requirementLabels.map((item) => (
                    <div key={item.key} className="mb-2.5 flex w-1/2 flex-row items-center">
                        {requirements[item.key] ? (
                            <CheckCircleIcon className="text-success size-4" />
                        ) : (
                            <XCircleIcon className="size-4 text-red-500" />
                        )}
                        <div className="ml-1">{item.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PasswordRequirements;
