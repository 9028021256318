import { useEffect, useRef, useState } from 'react';
import { useIsAppAdmin, useWaysteClient } from '@alliance-disposal/client';
import { useCurrentUserProfile } from '@alliance-disposal/client';
import { Hauler } from '@alliance-disposal/transport-types';
import { SourProvider } from '@wayste/sour-context';
import { SourSearchAppProvider, SourSearchProviderOld } from '@wayste/sour-search';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import Loading from './components/Loading';
import UnauthenticatedRoutes from './structure/Auth/unauthenticated-routes';
import InactiveAccountView from './structure/InactiveAccountView';
import InactiveUserView from './structure/InactiveUserView';
import Layout from './structure/Layout';
import routes from './utils/routes';

const App = () => {
    const client = useWaysteClient();
    const navigation = useNavigate();
    const { isAdmin } = useIsAppAdmin();
    const { profile: user } = useCurrentUserProfile();
    const [hauler, setHauler] = useState<Hauler.HaulerTransport | null>(null);
    const [authIsLoading, setAuthIsLoading] = useState<boolean | undefined>(true);
    const [haulerIsLoading, setHaulerIsLoading] = useState<boolean | undefined>(true);

    const authLoadedBefore = useRef<boolean>(false);

    const handleInitialSetupAfterAuth = () => {
        client.equipment().wayste.containerType.setEquipmentListInMemory();
    };

    useEffect(() => {
        const authLoading = client.auth().loading.subscribe((loading) => {
            if (authLoadedBefore.current) {
                // authLoading.unsubscribe();
                return;
            }
            if (!loading) {
                authLoadedBefore.current = true;
            }

            setAuthIsLoading(loading);
        });

        const sub = client.auth().currentAuthority.subscribe(async (auth) => {
            // authLoading.unsubscribe();

            // setIsLoggedIn(auth !== null && auth !== undefined);
            if (auth != null) {
                Sentry.setUser({ id: auth.profileID });
                authLoading.unsubscribe();
                setHaulerIsLoading(true);
                const fetch = await client.hauler().wayste.fetch();
                setHauler(fetch);
                handleInitialSetupAfterAuth();
            }
            setAuthIsLoading(false);
            setHaulerIsLoading(false);
        });
        return () => {
            sub.unsubscribe();
            authLoading.unsubscribe();
        };
    }, []);

    if (authIsLoading || haulerIsLoading) {
        return <Loading />;
    }

    // If the company (hauler) is inactive, show the inactive account view.
    if (hauler && hauler.active === false)
        return (
            <InactiveAccountView
                onSignOut={() => {
                    client.auth().signOut();
                    // setIsLoggedIn(false);
                }}
            />
        );

    // If the user is inactive, show the inactive user view.
    if (user && user.active === false)
        return (
            <InactiveUserView
                onSignOut={() => {
                    client.auth().signOut();
                }}
            />
        );

    if (user && hauler) {
        return (
            <SourSearchAppProvider
                environmentOptions={{
                    application: 'wayste',
                    apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                    environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                }}
                globalRestrictTo={hauler.id}
            >
                <SourProvider application="wayste" isAppAdmin={isAdmin}>
                    <SourSearchProviderOld
                        onSeeAllClick={(q) => {
                            navigation(routes.search.list + `?query[query]=${q}&sort[direction]=DESC&sour-search=true`);
                        }}
                    >
                        <Layout
                            userProfile={user}
                            onSignOut={() => {
                                client.auth().signOut();
                            }}
                        />
                    </SourSearchProviderOld>
                </SourProvider>
            </SourSearchAppProvider>
        );
    }

    return <UnauthenticatedRoutes />;
};

export default App;
